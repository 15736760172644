import { ColumnDef } from '@tanstack/react-table';
import {
  ItemAlert,
  SearchResultItemDto,
  SearchResultTimeViewDto,
} from 'apis/backendApi';
import cm from 'core/commonMessages';
import ItemDetailsLink from 'components/ItemDetailsLink';
import React, { useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useAvinModal } from 'views/Maintain/components/Result/AvinModal';
import {
  commercialInfoMessages,
  internalReplenishmentMessages,
  productInfoMessages,
  sellOutInfoMessages,
} from 'views/Maintain/tableMessages';
import { AvinAndSalesInfoSet } from 'views/Maintain/types';
import mm from 'views/Maintain/maintainMessages';
import Hyperlink from '@ingka/hyperlink';
import {
  useApiDateCell,
  useSimpleCurrencyCell,
} from 'views/Maintain/hooks/cells';
import { useSearchQueriesUrl } from 'views/Maintain/hooks/searchQueries';
import Tooltip from '@ingka/tooltip';

type ItemDto = SearchResultItemDto | SearchResultTimeViewDto;

export function useItemColumnDefs(summaryLabel?: MessageDescriptor) {
  const { $t } = useIntl();
  const { query } = useSearchQueriesUrl();

  const itemNo: ColumnDef<ItemDto> = {
    id: 'item-no',
    meta: {
      headerMessages: {
        text: $t(cm.articleNumber),
        tooltip: $t(cm.articleNumberTooltip),
      },
    },
    accessorKey: 'itemNo',
    cell: props => props.getValue() as string,
  };

  const itemName: ColumnDef<ItemDto | ItemAlert, ItemDto | ItemAlert> = {
    id: 'item-name',
    meta: {
      headerMessages: {
        text: $t(cm.articleName),
        tooltip: $t(cm.articleNameTooltip),
      },
    },
    accessorFn: dto => dto,
    cell: props => {
      const { itemNo, itemName } = props.getValue();
      return (
        <ItemDetailsLink itemNo={itemNo} query={query}>
          {itemName ?? $t(cm.noDescription)}
        </ItemDetailsLink>
      );
    },
    footer: () => summaryLabel && $t(summaryLabel),
  };

  return { itemNo, itemName };
}

function avinCellText({
  availability,
  salesInfo,
  salesInfoFromDate,
}: AvinAndSalesInfoSet): string | null {
  if ((availability.length === 1 && salesInfo) || availability.length >= 2) {
    return 'Y';
  } else if (availability.length === 1) {
    return `${availability[0].codeDescription.substring(0, 14)}: ${
      availability[0].toDate
    }`;
  } else if (salesInfo) {
    return `${salesInfo.substring(0, 14)}: ${salesInfoFromDate}`;
  } else {
    return null;
  }
}

export const useAvailabilityColumnDef: () => ColumnDef<SearchResultItemDto> =
  () => {
    const showAvin = useAvinModal();
    const { $t } = useIntl();

    return useMemo(
      () => ({
        accessorFn: ({
          commercialInfo: { availability, salesInfo, salesInfoFromDate },
        }) => {
          return {
            availability: availability ?? [],
            salesInfo,
            salesInfoFromDate,
          };
        },
        id: 'commercialInfo_availability',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.availability),
            tooltip: $t(commercialInfoMessages.availabilityTooltip),
          },
        },
        cell: props => {
          const avinInfoSet =
            props.getValue() as unknown as AvinAndSalesInfoSet;
          const cellText = avinCellText(avinInfoSet);
          return !cellText ? (
            ''
          ) : (
            <Hyperlink
              style={{ display: 'block' }} // Easier to click when just 'Y'
              button
              onClick={e => {
                e.preventDefault();
                showAvin(avinInfoSet);
              }}
              text={cellText}
            />
          );
        },
      }),
      [$t, showAvin]
    );
  };

export const useEdsColumnDef = (): ColumnDef<SearchResultItemDto, string> => {
  const { $t } = useIntl();
  const dateCell = useApiDateCell();
  return useMemo(
    () => ({
      accessorFn: ({ productInfo }) => {
        return productInfo.endDateSale;
      },
      id: 'productInfo_eds',
      meta: {
        headerMessages: {
          text: $t(mm.maintainEndSalesDate),
          tooltip: $t(mm.maintainEndSalesDateTooltip),
        },
      },
      cell: dateCell,
    }),
    [$t, dateCell]
  );
};

export const usePriceColumnDef = (): ColumnDef<SearchResultItemDto, number> => {
  const { $t } = useIntl();
  const currencyCell = useSimpleCurrencyCell();
  return useMemo(
    () => ({
      accessorFn: ({ productInfo }) => productInfo.regularPrice,
      id: 'productInfo_price',
      meta: {
        headerMessages: {
          text: $t(productInfoMessages.price),
          tooltip: $t(productInfoMessages.priceTooltip),
        },
      },
      cell: currencyCell,
    }),
    [$t, currencyCell]
  );
};

export const useLocalPriceColumnDef = (): ColumnDef<
  SearchResultItemDto,
  number
> => {
  const { $t } = useIntl();
  const currencyCell = useSimpleCurrencyCell();
  return useMemo(
    () => ({
      accessorFn: ({ productInfo }) => productInfo.localPrice,
      id: 'productInfo_localPrice',
      meta: {
        headerMessages: {
          text: $t(productInfoMessages.localPrice),
          tooltip: $t(productInfoMessages.localPriceTooltip),
        },
      },
      cell: currencyCell,
    }),
    [$t, currencyCell]
  );
};

export const useFamilyPriceColumnDef = (): ColumnDef<
  SearchResultItemDto,
  number
> => {
  const { $t } = useIntl();
  const currencyCell = useSimpleCurrencyCell();
  return useMemo(
    () => ({
      accessorFn: ({ productInfo }) => productInfo.familyPrice,
      id: 'productInfo_familyPrice',
      meta: {
        headerMessages: {
          text: $t(productInfoMessages.familyPrice),
          tooltip: $t(productInfoMessages.familyPriceTooltip),
        },
      },
      cell: currencyCell,
    }),
    [$t, currencyCell]
  );
};

export const useForecastColumnDef = (): ColumnDef<
  SearchResultItemDto,
  number
> => {
  const { $t } = useIntl();
  return useMemo(
    () => ({
      accessorFn: (data: SearchResultItemDto) => data.commercialInfo.forecast,
      id: 'commercialInfo_forecast',
      meta: {
        headerMessages: {
          text: $t(commercialInfoMessages.forecast),
          tooltip: $t(commercialInfoMessages.forecastTooltip),
        },
      },
    }),
    [$t]
  );
};

/*

 */
export function useStockFigureColumnDef(
  stockFigureFieldName: 'currentTotStockQty' | 'sgfStock'
): ColumnDef<
  SearchResultItemDto,
  {
    sgfStock: number;
    currentTotStockQty: number;
    stockMetaSource: string;
    stockMetaLastUpdated: Date;
  }
> {
  const { $t, formatNumber, formatDate } = useIntl();
  return useMemo(
    () => ({
      id: `stockFigure_${stockFigureFieldName}`,
      meta: {
        headerMessages: {
          text: $t(
            stockFigureFieldName === 'currentTotStockQty'
              ? sellOutInfoMessages.currentTotalStockQuantity
              : internalReplenishmentMessages.bufferStock
          ),
          tooltip: $t(
            stockFigureFieldName === 'currentTotStockQty'
              ? sellOutInfoMessages.currentTotalStockQuantityTooltip
              : internalReplenishmentMessages.bufferStockTooltip
          ),
        },
      },
      accessorFn: (data: SearchResultItemDto) => ({
        sgfStock: data.internalReplenishment.sgfStock,
        stockMetaLastUpdated: data.sellOutInfo.stockMetaLastUpdated,
        stockMetaSource: data.sellOutInfo.stockMetaSource,
        currentTotStockQty: data.sellOutInfo.currentTotStockQty,
      }),
      cell: props => {
        const value = props.getValue();
        const stockFigure = formatNumber(value[stockFigureFieldName], {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0, //TODO unit2/fabric
        });
        const lastUpdated =
          value.stockMetaSource === 'GIS'
            ? `${formatDate(value.stockMetaLastUpdated, {
                dateStyle: 'short',
                timeStyle: 'short',
              })}`
            : $t(cm.valueAtNightBatch);
        return (
          <>
            <Tooltip tooltipText={lastUpdated}>
              <>{stockFigure}</>
            </Tooltip>
          </>
        );
      },
    }),
    [stockFigureFieldName, $t, formatNumber, formatDate]
  );
}
