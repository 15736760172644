import React from 'react';
import { PaginatedItemSearchResultDto } from 'apis/backendApi';
import Tabs, { Tab } from '@ingka/tabs';
import SSRIcon from '@ingka/ssr-icon';
import { calendar } from '@ingka/ssr-icon/icons';
import AttributeDateInput from 'views/Maintain/components/Result/AttributeDateInput';
import { useIntl } from 'react-intl';
import mm from '../../maintainMessages';
import CommercialInfoTable from 'views/Maintain/components/Result/CommercialInfoTable';
import SpaceNeedTable from 'views/Maintain/components/Result/SpaceNeedTable';
import { useSingleSearchParamState } from 'hooks/routing';
import TimeViewTable from 'views/Maintain/components/Result/TimeViewTable';
import { CommonTableProps } from 'views/Maintain/types';
import AttributesTable from 'views/Maintain/components/Result/AttributesTable';
import SsqInfoTable from 'views/Maintain/components/Result/SsqInfoTable';
import DimensionsTable from 'views/Maintain/components/Result/DimensionsTable';
import SellOutInfoTable from 'views/Maintain/components/Result/SellOutInfoTable';
import ProductInfoTable from 'views/Maintain/components/Result/ProductInfoTable';
import InternalReplenishmentTable from 'views/Maintain/components/Result/InternalReplenishmentTable';

type ResultTabsProps = {
  attributesData: PaginatedItemSearchResultDto;
  timeViewData: PaginatedItemSearchResultDto;
  summary: {
    isLoading: boolean;
    data: { fillingRate: number } | undefined;
  };
};

enum ResultTabSet {
  TIMEVIEW = 'timeview',
  COMMERCIAL_INFO = 'commercialInfo',
  SPACE_NEED = 'spaceNeed',
  GOODS_FLOW = 'goodsFlow',
  INTERNAL_REPLENISHMENT = 'internalRepl',
  PRODUCT_INFO = 'productInfo',
  SSQ_INFO = 'ssqInfo',
  DIMENSIONS = 'dimensions',
  SELL_OUT_INFO = 'sellOutInfo',
}

const PlaceholderTable: React.FC<CommonTableProps> = ({ data, summary }) => {
  return <AttributesTable data={data} summary={summary} columns={[]} footer />;
};

const ResultTabs: React.FC<ResultTabsProps> = ({
  attributesData,
  timeViewData,
  summary,
}) => {
  const { $t } = useIntl();
  const [selectedTab, setSelectedTab] = useSingleSearchParamState(
    'tab',
    ResultTabSet.TIMEVIEW
  );

  const tabs = [
    <Tab
      key={ResultTabSet.TIMEVIEW}
      text={
        <span>
          <SSRIcon paths={calendar} />
          {$t(mm.maintainResultTabLabelTimeView)}
        </span>
      }
      tabPanelId={ResultTabSet.TIMEVIEW}
    />,
    <Tab
      key={ResultTabSet.COMMERCIAL_INFO}
      text={$t(mm.maintainResultTabLabelCommercialInfo)}
      tabPanelId={ResultTabSet.COMMERCIAL_INFO}
    />,
    <Tab
      key={ResultTabSet.SPACE_NEED}
      text={$t(mm.maintainResultTabLabelSpaceNeed)}
      tabPanelId={ResultTabSet.SPACE_NEED}
    />,
    <Tab
      key={ResultTabSet.GOODS_FLOW}
      text={$t(mm.maintainResultTabLabelGoodsFlow)}
      tabPanelId={ResultTabSet.GOODS_FLOW}
    />,
    <Tab
      key={ResultTabSet.INTERNAL_REPLENISHMENT}
      text={$t(mm.maintainResultTabLabelInternalRepl)}
      tabPanelId={ResultTabSet.INTERNAL_REPLENISHMENT}
    />,
    <Tab
      key={ResultTabSet.PRODUCT_INFO}
      text={$t(mm.maintainResultTabLabelProductInfo)}
      tabPanelId={ResultTabSet.PRODUCT_INFO}
    />,
    <Tab
      key={ResultTabSet.SSQ_INFO}
      text={$t(mm.maintainResultTabLabelSsqInfo)}
      tabPanelId={ResultTabSet.SSQ_INFO}
    />,
    <Tab
      key={ResultTabSet.DIMENSIONS}
      text={$t(mm.maintainResultTabLabelDimensions)}
      tabPanelId={ResultTabSet.DIMENSIONS}
    />,
    <Tab
      key={ResultTabSet.SELL_OUT_INFO}
      text={$t(mm.maintainResultTabLabelSellOutInfo)}
      tabPanelId={ResultTabSet.SELL_OUT_INFO}
    />,
  ];

  return (
    <>
      <Tabs tabs={tabs} activeTab={selectedTab} onTabChanged={setSelectedTab} />
      <div
        className="tabs__panel"
        style={{
          display: selectedTab === ResultTabSet.TIMEVIEW ? 'inherit' : 'none',
        }}
      >
        {timeViewData && <TimeViewTable data={timeViewData} />}
      </div>
      <div
        className="tabs__panel"
        style={{
          display: selectedTab !== ResultTabSet.TIMEVIEW ? 'inherit' : 'none',
        }}
      >
        <AttributeDateInput />
        {selectedTab === ResultTabSet.COMMERCIAL_INFO && (
          <CommercialInfoTable data={attributesData} summary={summary} />
        )}
        {selectedTab === ResultTabSet.SPACE_NEED && (
          <SpaceNeedTable data={attributesData} summary={summary} />
        )}
        {selectedTab === ResultTabSet.GOODS_FLOW && (
          <PlaceholderTable data={attributesData} />
        )}
        {selectedTab === ResultTabSet.PRODUCT_INFO && (
          <ProductInfoTable data={attributesData} />
        )}
        {selectedTab === ResultTabSet.INTERNAL_REPLENISHMENT && (
          <InternalReplenishmentTable data={attributesData} />
        )}
        {selectedTab === ResultTabSet.SSQ_INFO && (
          <SsqInfoTable data={attributesData} />
        )}
        {selectedTab === ResultTabSet.DIMENSIONS && (
          <DimensionsTable data={attributesData} />
        )}
        {selectedTab === ResultTabSet.SELL_OUT_INFO && (
          <SellOutInfoTable data={attributesData} summary={summary} />
        )}
      </div>
    </>
  );
};

export default ResultTabs;
