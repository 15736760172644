import { defineMessages } from 'react-intl';
import {
  DeliveryTypeReasonEnum,
  KeyArticleEnum,
  PriceLevelEnum,
} from 'apis/backendApi';
import { MessageDescriptorWithEnum } from 'views/Maintain/types';

const commercialInfoMessages = defineMessages({
  availability: {
    id: 'maintainResultTableHeaderAvailability',
    defaultMessage: 'Availability',
    description: 'Header for Commercial info table, column Availability',
  },
  availabilityTooltip: {
    id: 'maintainResultTableHeaderAvailabilityTooltip',
    defaultMessage: 'Availability',
    description:
      'Header tooltip for Commercial info table, column Availability',
  },

  turnover: {
    id: 'maintainResultTableHeaderTurnover',
    defaultMessage: 'Turnover',
    description: 'Header for Commercial info table, column Turnover',
  },
  turnoverTooltip: {
    id: 'maintainResultTableHeaderTurnoverTooltip',
    defaultMessage: 'Turnover',
    description: 'Header tooltip for Commercial info table, column Turnover',
  },

  averageWeeklySales: {
    id: 'maintainResultTableHeaderAverageWeeklySales',
    defaultMessage: 'AWS',
    description: 'Header for Commercial info table, column AverageWeeklySales',
  },
  averageWeeklySalesTooltip: {
    id: 'maintainResultTableHeaderAverageWeeklySalesTooltip',
    defaultMessage: 'Average Weekly Sales',
    description:
      'Header tooltip for Commercial info table, column AverageWeeklySales',
  },

  forecast: {
    id: 'maintainResultTableHeaderForecast',
    defaultMessage: 'Forecast',
    description: 'Header for Commercial info table, column Forecast',
  },
  forecastTooltip: {
    id: 'maintainResultTableHeaderForecastTooltip',
    defaultMessage: 'Forecast',
    description: 'Header tooltip for Commercial info table, column Forecast',
  },

  biggestPeakDayForecast: {
    id: 'maintainResultTableHeaderBiggestPeakDayForecast',
    defaultMessage: 'BPDF',
    description:
      'Header tooltip for Commercial info table, column BiggestPeakDayForecast',
  },
  biggestPeakDayForecastTooltip: {
    id: 'maintainResultTableHeaderBiggestPeakDayForecastTooltip',
    defaultMessage: 'Biggest Peak Day Forecast',
    description:
      'Header for Commercial info table, column BiggestPeakDayForecast',
  },

  replenishmentPoint: {
    id: 'maintainResultTableHeaderReplenishmentPoint',
    defaultMessage: 'Repl. Point',
    description:
      'Header tooltip for Commercial info table, column ReplenishmentPoint',
  },
  replenishmentPointTooltip: {
    id: 'maintainResultTableHeaderReplenishmentPointTooltip',
    defaultMessage: 'Replenishment Point',
    description:
      'Header tooltip for Commercial info table, column ReplenishmentPoint',
  },

  averageSalesPerReceipt: {
    id: 'maintainResultTableHeaderAverageSalesPerReceipt',
    defaultMessage: 'Avg.Check',
    description:
      'Header for Commercial info table, column AverageSalesPerReceipt',
  },
  averageSalesPerReceiptTooltip: {
    id: 'maintainResultTableHeaderAverageSalesPerReceiptTooltip',
    defaultMessage: 'Average Sales Per Receipt',
    description:
      'Header tooltip for Commercial info table, column AverageSalesPerReceipt',
  },

  minimumSafetyStock: {
    id: 'maintainResultTableHeaderMinimumSafetyStock',
    defaultMessage: 'Min SS',
    description: 'Header for Commercial info table, column MinimumSafetyStock',
  },
  minimumSafetyStockTooltip: {
    id: 'maintainResultTableHeaderMinimumSafetyStockTooltip',
    defaultMessage: 'Minimum Safety Stock',
    description:
      'Header tooltip for Commercial info table, column MinimumSafetyStock',
  },

  lowestSalesQuantity: {
    id: 'maintainResultTableHeaderLowestSalesQuantity',
    defaultMessage: 'LSQ',
    description: 'Header for Commercial info table, column LowestSalesQuantity',
  },
  lowestSalesQuantityTooltip: {
    id: 'maintainResultTableHeaderLowestSalesQuantityTooltip',
    defaultMessage: 'Lowest Sales Quantity',
    description:
      'Header tooltip for Commercial info table, column LowestSalesQuantity',
  },

  mathSafetyStock: {
    id: 'maintainResultTableHeaderMathSafetyStock',
    defaultMessage: 'Math SS',
    description: 'Header for Commercial info table, column MathSafetyStock',
  },
  mathSafetyStockTooltip: {
    id: 'maintainResultTableHeaderMathSafetyStockTooltip',
    defaultMessage: 'Mathematical Safety Stock',
    description:
      'Header tooltip for Commercial info table, column MathSafetyStock',
  },

  maxSafetyStock: {
    id: 'maintainResultTableHeaderMaxSafetyStock',
    defaultMessage: 'Max SS',
    description: 'Header for Commercial info table, column MaxSafetyStock',
  },
  maxSafetyStockTooltip: {
    id: 'maintainResultTableHeaderMaxSafetyStockTooltip',
    defaultMessage: 'Maximum Safety Stock',
    description:
      'Header tooltip for Commercial info table, column MaxSafetyStock',
  },

  commercialQuantity: {
    id: 'maintainResultTableHeaderCommercialQuantity',
    defaultMessage: 'Comm.Qty',
    description: 'Header for Commercial info table, column CommercialQuantity',
  },
  commercialQuantityTooltip: {
    id: 'maintainResultTableHeaderCommercialQuantityTooltip',
    defaultMessage: 'Commercial Quantity',
    description:
      'Header tooltip for Commercial info table, column CommercialQuantity',
  },

  fillingRate: {
    id: 'maintainResultTableHeaderFillingRate',
    defaultMessage: 'Filling Rate',
    description: 'Header for Commercial info table, column FillingRate',
  },
  fillingRateTooltip: {
    id: 'maintainResultTableHeaderFillingRateTooltip',
    defaultMessage: 'Filling Rate',
    description: 'Header tooltip for Commercial info table, column FillingRate',
  },

  turnoverMarkLow: {
    id: 'maintainResultTurnoverLow',
    defaultMessage: 'Low',
    description: 'Value text for column Turnover, for Low Turnover',
  },
  turnoverMarkMedium: {
    id: 'maintainResultTurnoverMedium',
    defaultMessage: 'Medium',
    description: 'Value text for column Turnover, for Medium Turnover',
  },
  turnoverMarkHigh: {
    id: 'maintainResultTurnoverHigh',
    defaultMessage: 'High',
    description: 'Value text for column Turnover, for High Turnover',
  },
});

const spaceNeedMessages = defineMessages({
  backpanelNeed: {
    id: 'maintainResultTableHeaderBackpanelNeed',
    defaultMessage: 'Backpanel Need',
    description: 'Header for Space Need table, column BackpanelNeed',
  },
  backpanelNeedTooltip: {
    id: 'maintainResultTableHeaderBackpanelNeedTooltip',
    defaultMessage: 'Backpanel Need',
    description: 'Header tooltip for Space Need table, column BackpanelNeed',
  },

  halfPalletNeed: {
    id: 'maintainResultTableHeaderHalfPalletNeed',
    defaultMessage: 'Half Pallet Need',
    description: 'Header for Space Need table, column HalfPalletNeed',
  },
  halfPalletNeedTooltip: {
    id: 'maintainResultTableHeaderHalfPalletNeedTooltip',
    defaultMessage: 'Half Pallet Need',
    description: 'Header tooltip for Space Need table, column HalfPalletNeed',
  },

  fullPalletNeed: {
    id: 'maintainResultTableHeaderFullPalletNeed',
    defaultMessage: 'Full Pallet Need',
    description: 'Header for Space Need table, column FullPalletNeed',
  },
  fullPalletNeedTooltip: {
    id: 'maintainResultTableHeaderFullPalletNeedTooltip',
    defaultMessage: 'Full Pallet Need',
    description: 'Header tooltip for Space Need table, column FullPalletNeed',
  },

  longPalletNeed: {
    id: 'maintainResultTableHeaderLongPalletNeed',
    defaultMessage: 'Long Pallet Need',
    description: 'Header for Space Need table, column LongPalletNeed',
  },
  longPalletNeedTooltip: {
    id: 'maintainResultTableHeaderLongPalletNeedTooltip',
    defaultMessage: 'Long Pallet Need',
    description: 'Header tooltip for Space Need table, column LongPalletNeed',
  },

  handstackLocationNeed: {
    id: 'maintainResultTableHeaderHandstackLocationNeed',
    defaultMessage: 'Handstack Location Need',
    description: 'Header for Space Need table, column HandstackLocationNeed',
  },
  handstackLocationNeedTooltip: {
    id: 'maintainResultTableHeaderHandstackLocationNeedTooltip',
    defaultMessage: 'Handstack Location Need',
    description:
      'Header tooltip for Space Need table, column HandstackLocationNeed',
  },

  spaceNeed: {
    id: 'maintainResultTableHeaderSpaceNeed',
    defaultMessage: 'M2 Need',
    description: 'Header for Space Need table, column SpaceNeed',
  },
  spaceNeedTooltip: {
    id: 'maintainResultTableHeaderSpaceNeedTooltip',
    defaultMessage: 'Space Need',
    description: 'Header tooltip for Space Need table, column SpaceNeed',
  },

  backpanelUtilization: {
    id: 'maintainResultTableHeaderBackpanelUtilization',
    defaultMessage: 'Backpanel Utilization',
    description:
      'Header for Space Utilization table, column BackpanelUtilization',
  },
  backpanelUtilizationTooltip: {
    id: 'maintainResultTableHeaderBackpanelUtilizationTooltip',
    defaultMessage: 'Backpanel Utilization',
    description:
      'Header tooltip for Space Utilization table, column BackpanelUtilization',
  },

  halfPalletUtilization: {
    id: 'maintainResultTableHeaderHalfPalletUtilization',
    defaultMessage: 'Half Pallet Utilization',
    description:
      'Header for Space Utilization table, column HalfPalletUtilization',
  },
  halfPalletUtilizationTooltip: {
    id: 'maintainResultTableHeaderHalfPalletUtilizationTooltip',
    defaultMessage: 'Half Pallet Utilization',
    description:
      'Header tooltip for Space Utilization table, column HalfPalletUtilization',
  },

  fullPalletUtilization: {
    id: 'maintainResultTableHeaderFullPalletUtilization',
    defaultMessage: 'Full Pallet Utilization',
    description:
      'Header for Space Utilization table, column FullPalletUtilization',
  },
  fullPalletUtilizationTooltip: {
    id: 'maintainResultTableHeaderFullPalletUtilizationTooltip',
    defaultMessage: 'Full Pallet Utilization',
    description:
      'Header tooltip for Space Utilization table, column FullPalletUtilization',
  },

  longPalletUtilization: {
    id: 'maintainResultTableHeaderLongPalletUtilization',
    defaultMessage: 'Long Pallet Utilization',
    description:
      'Header for Space Utilization table, column LongPalletUtilization',
  },
  longPalletUtilizationTooltip: {
    id: 'maintainResultTableHeaderLongPalletUtilizationTooltip',
    defaultMessage: 'Long Pallet Utilization',
    description:
      'Header tooltip for Space Utilization table, column LongPalletUtilization',
  },

  handstackLocationUtilization: {
    id: 'maintainResultTableHeaderHandstackLocationUtilization',
    defaultMessage: 'Handstack Location Utilization',
    description:
      'Header for Space Utilization table, column HandstackLocationUtilization',
  },
  handstackLocationUtilizationTooltip: {
    id: 'maintainResultTableHeaderHandstackLocationUtilizationTooltip',
    defaultMessage: 'Handstack Location Utilization',
    description:
      'Header tooltip for Space Utilization table, column HandstackLocationUtilization',
  },

  spaceUtilization: {
    id: 'maintainResultTableHeaderSpaceUtilization',
    defaultMessage: 'M2 Utilization',
    description: 'Header for Space Utilization table, column SpaceUtilization',
  },
  spaceUtilizationTooltip: {
    id: 'maintainResultTableHeaderSpaceUtilizationTooltip',
    defaultMessage: 'Space Utilization',
    description:
      'Header tooltip for Space Utilization table, column SpaceUtilization',
  },
});

const ssqInfoMessages = defineMessages({
  minSsq: {
    id: 'maintainResultTableHeaderMinSsq',
    defaultMessage: 'Min',
    description: 'Header for SSQ Info table, column Min',
  },
  minSsqTooltip: {
    id: 'maintainResultTableHeaderMinSsqToolTip',
    defaultMessage: 'Minimum Sales Space',
    description: 'Header tooltip for SSQ Info table, column Min',
  },

  dtfp: {
    id: 'maintainResultTableHeaderDtfp',
    defaultMessage: 'DTFP',
    description: 'Header for SSQ Info table, column DTFP',
  },
  dtfpTooltip: {
    id: 'maintainResultTableHeaderDtfpTooltip',
    defaultMessage: 'Door to Floor Point',
    description: 'Header tooltip for SSQ Info table, column DTFP',
  },

  dtfpPlus: {
    id: 'maintainResultTableHeaderDtfpPlus',
    defaultMessage: 'DTFP+',
    description: 'Header for SSQ Info table, column DTF',
  },
  dtfpPlusTooltip: {
    id: 'maintainResultTableHeaderDtfpPlusTooltip',
    defaultMessage: 'Door to Floor Point Plus',
    description: 'Header tooltip for SSQ Info table, column DTFP+',
  },

  maxSsq: {
    id: 'maintainResultTableHeaderMax',
    defaultMessage: 'Max',
    description: 'Header for SSQ Info table, column Max',
  },
  maxSsqTooltip: {
    id: 'maintainResultTableHeader_Tooltip',
    defaultMessage: 'Maximum Sales Space',
    description: 'Header tooltip for SSQ Info table, column Max',
  },

  rssq: {
    id: 'maintainResultTableHeaderRssq',
    defaultMessage: 'RSSQ',
    description: 'Header for SSQ Info table, column RSSQ',
  },
  rssqTooltip: {
    id: 'maintainResultTableHeaderRssqTooltip',
    defaultMessage: 'Recommended Sales Space Quantity',
    description: 'Header tooltip for SSQ Info table, column RSSQ',
  },

  assq: {
    id: 'maintainResultTableHeaderAssq',
    defaultMessage: 'ASSQ',
    description: 'Header for SSQ Info table, column ASSQ',
  },
  assqTooltip: {
    id: 'maintainResultTableHeaderAssqTooltip',
    defaultMessage: 'Assigned Sales Space Quantity',
    description: 'Header tooltip for SSQ Info table, column ASSQ',
  },

  deliveryType: {
    id: 'maintainResultTableHeaderDeliveryType',
    defaultMessage: 'DT',
    description: 'Header for SSQ Info table, column DT',
  },
  deliveryTypeTooltip: {
    id: 'maintainResultTableHeaderDeliveryTypeTooltip',
    defaultMessage: 'Delivery Type 0 = Multipack 1 = Pallet',
    description: 'Header tooltip for SSQ Info table, column DT',
  },

  deliveryTypeReason: {
    id: 'maintainResultTableHeaderDeliveryTypeReason',
    defaultMessage: 'DT Reason',
    description: 'Header for SSQ Info table, column DT Reason',
  },
  deliveryTypeReasonTooltip: {
    id: 'maintainResultTableHeaderDeliveryTypeReasonTooltip',
    // TODO: Display line breaks in tooltip or convey this information in another way.
    defaultMessage: `
      Pick: DT=0,Item is picked at DCG
      MupQty = PalQty: DT=1 due to Multipack Qty = Pallet Qty
      MDQQty = PalQty: DT=1 due to Minimum Delivery Qty 0 Pallet Qty
      PRF: DT = 1 due to setting of Pallet Rounding Factor
      BPDF: DT = 1 due to Biggest Peakday Forecast
      PalQty = 0:DT =0 due to PalQty = 0
      DM non DCG: DT=1 due to Article is DD or Combined Supply
      OFO: DT = 1 due to setting of Only Fullpallet Ordering`,
    description: 'Header tooltip for SSQ Info table, column DT Reason',
  },
  deliveryTypeReasonPick: {
    id: 'maintainResultTableHeaderDeliveryTypeReasonPick',
    defaultMessage: 'Pick',
    enumValue: DeliveryTypeReasonEnum.PICK,
  },
  deliveryTypeReasonMupqEqPalq: {
    id: 'maintainResultTableHeaderDeliveryTypeReasonMupqEqPalq',
    defaultMessage: 'MupQty=PalQty',
    enumValue: DeliveryTypeReasonEnum.MUPQ_EQ_PALQ,
  },
  deliveryTypeReasonMdqEqPlq: {
    id: 'maintainResultTableHeaderDeliveryTypeReasonMdqEqPlq',
    defaultMessage: 'MdqQty=PalQty',
    enumValue: DeliveryTypeReasonEnum.MDQ_EQ_PALQ,
  },
  deliveryTypeReasonPalletFits: {
    id: 'maintainResultTableHeaderDeliveryTypeReasonPalletFits',
    defaultMessage: 'PRF',
    enumValue: DeliveryTypeReasonEnum.PALLET_FITS,
  },
  deliveryTypeReasonDailySalesGrPalq: {
    id: 'maintainResultTableHeaderDeliveryTypeReasonDailySalesGrPalq',
    defaultMessage: 'BPDF',
    enumValue: DeliveryTypeReasonEnum.DAILY_SALES_GR_PALQ,
  },
  deliveryTypeReasonDwpPalqEq0: {
    id: 'maintainResultTableHeaderDeliveryTypeReasonDwpPalqEq0',
    defaultMessage: 'PalQty=0',
    enumValue: DeliveryTypeReasonEnum.DWP_PALQ_EQ_0,
  },
  deliveryTypeReasonDirectDelivery: {
    id: 'maintainResultTableHeaderDeliveryTypeReasonDirectDelivery',
    defaultMessage: 'DM non-DCG',
    enumValue: DeliveryTypeReasonEnum.DIRECT_DELIVERY,
  },
  deliveryTypeReasonOrderFullPltOnlyEq1: {
    id: 'maintainResultTableHeaderDeliveryTypeReasonOrderFullPltOnlyEq1',
    defaultMessage: 'OFO',
    enumValue: DeliveryTypeReasonEnum.ORDER_FULL_PLT_ONLY_EQ_1,
  },

  mdq: {
    id: 'maintainResultTableHeaderMdq',
    defaultMessage: 'MDQ',
    description: 'Header for SSQ Info table, column MDQ',
  },
  mdqTooltip: {
    id: 'maintainResultTableHeaderMdqTooltip',
    defaultMessage: `Minimum Delivery Quantity`,
    description: 'Header tooltip for SSQ Info table, column MDQ',
  },

  recMerch: {
    id: 'maintainResultTableHeaderRecMerch',
    defaultMessage: 'Rec.Merch',
    description: 'Header for SSQ Info table, column Rec.Merch',
  },
  recMerchTooltip: {
    id: 'maintainResultTableHeaderRecMerchTooltip',
    defaultMessage: 'Recommended Merchandising Solution',
    description: 'Header tooltip for SSQ Info table, column Rec.Merch',
  },
});

const deliveryTypeReasonMessages: Record<string, MessageDescriptorWithEnum> =
  defineMessages({
    deliveryTypeReasonPick: {
      id: 'maintainResultTableHeaderDeliveryTypeReasonPick',
      defaultMessage: 'Pick',
      enumValue: DeliveryTypeReasonEnum.PICK,
    },
    deliveryTypeReasonMupqEqPalq: {
      id: 'maintainResultTableHeaderDeliveryTypeReasonMupqEqPalq',
      defaultMessage: 'MupQty=PalQty',
      enumValue: DeliveryTypeReasonEnum.MUPQ_EQ_PALQ,
    },
    deliveryTypeReasonMdqEqPlq: {
      id: 'maintainResultTableHeaderDeliveryTypeReasonMdqEqPlq',
      defaultMessage: 'MdqQty=PalQty',
      enumValue: DeliveryTypeReasonEnum.MDQ_EQ_PALQ,
    },
    deliveryTypeReasonPalletFits: {
      id: 'maintainResultTableHeaderDeliveryTypeReasonPalletFits',
      defaultMessage: 'PRF',
      enumValue: DeliveryTypeReasonEnum.PALLET_FITS,
    },
    deliveryTypeReasonDailySalesGrPalq: {
      id: 'maintainResultTableHeaderDeliveryTypeReasonDailySalesGrPalq',
      defaultMessage: 'BPDF',
      enumValue: DeliveryTypeReasonEnum.DAILY_SALES_GR_PALQ,
    },
    deliveryTypeReasonDwpPalqEq0: {
      id: 'maintainResultTableHeaderDeliveryTypeReasonDwpPalqEq0',
      defaultMessage: 'PalQty=0',
      enumValue: DeliveryTypeReasonEnum.DWP_PALQ_EQ_0,
    },
    deliveryTypeReasonDirectDelivery: {
      id: 'maintainResultTableHeaderDeliveryTypeReasonDirectDelivery',
      defaultMessage: 'DM non-DCG',
      enumValue: DeliveryTypeReasonEnum.DIRECT_DELIVERY,
    },
    deliveryTypeReasonOrderFullPltOnlyEq1: {
      id: 'maintainResultTableHeaderDeliveryTypeReasonOrderFullPltOnlyEq1',
      defaultMessage: 'OFO',
      enumValue: DeliveryTypeReasonEnum.ORDER_FULL_PLT_ONLY_EQ_1,
    },
  });

const dimensionsMessages = defineMessages({
  consumerPackHeight: {
    id: 'maintainResultTableConsumerPackHeight',
    defaultMessage: 'CP Height',
    description: 'Header for Dimensions table, column Consumer Package Height',
  },
  consumerPackHeightTooltip: {
    id: 'maintainResultTableConsumerPackHeightTooltip',
    defaultMessage: 'Consumer Package Height (mm)',
    description:
      'Header tooltip for Dimensions table, column Consumer Package Height',
  },
  consumerPackLength: {
    id: 'maintainResultTableConsumerPackLength',
    defaultMessage: 'CP Length',
    description: 'Header for Dimensions table, column Consumer Package Length',
  },
  consumerPackLengthTooltip: {
    id: 'maintainResultTableConsumerPackLengthTooltip',
    defaultMessage: 'Consumer Package Length (mm)',
    description:
      'Header tooltip for Dimensions table, column Consumer Package Length',
  },
  consumerPackWidth: {
    id: 'maintainResultTableConsumerPackWidth',
    defaultMessage: 'CP Width',
    description: 'Header for Dimensions table, column Consumer Package Width',
  },
  consumerPackWidthTooltip: {
    id: 'maintainResultTableConsumerPackWidthTooltip',
    defaultMessage: 'Consumer Package Width (mm)',
    description:
      'Header tooltip for Dimensions table, column Consumer Package Width',
  },
  consumerPackDiameter: {
    id: 'maintainResultTableConsumerPackDiameter',
    defaultMessage: 'CP Diameter',
    description:
      'Header for Dimensions table, column Consumer Package Diameter',
  },
  consumerPackDiameterTooltip: {
    id: 'maintainResultTableConsumerPackDiameterTooltip',
    defaultMessage: 'Consumer Package Diameter (mm)',
    description:
      'Header tooltip for Dimensions table, column Consumer Package Diameter',
  },
  consumerPackGrossWeight: {
    id: 'maintainResultTableConsumerPackGrossWeight',
    defaultMessage: 'CP Weight Gross',
    description:
      'Header for Dimensions table, column Consumer Package Weight Gross',
  },
  consumerPackGrossWeightTooltip: {
    id: 'maintainResultTableConsumerPackGrossWeightTooltip',
    defaultMessage: 'Consumer Package Gross Weight (kg)',
    description:
      'Header tooltip for Dimensions table, column Consumer Package Weight Gross',
  },
  unitLoadLength: {
    id: 'maintainResultTableUnitLoadLength',
    defaultMessage: 'UL Length',
    description: 'Header for Dimensions table, column Unit Load Length',
  },
  unitLoadLengthTooltip: {
    id: 'maintainResultTableUnitLoadLengthTooltip',
    defaultMessage: 'Unit Load Length (mm)',
    description: 'Header tooltip for Dimensions table, column Unit Load Length',
  },
  unitLoadHeight: {
    id: 'maintainResultTableUnitLoadHeight',
    defaultMessage: 'UL Height',
    description: 'Header for Dimensions table, column Unit Load Height',
  },
  unitLoadHeightTooltip: {
    id: 'maintainResultTableUnitLoadHeightTooltip',
    defaultMessage: 'Unit Load Height (mm)',
    description: 'Header tooltip for Dimensions table, column Unit Load Height',
  },
  unitLoadWidth: {
    id: 'maintainResultTableUnitLoadWidth',
    defaultMessage: 'UL Width',
    description: 'Header for Dimensions table, column Unit Load Width',
  },
  unitLoadWidthTooltip: {
    id: 'maintainResultTableUnitLoadWidthTooltip',
    defaultMessage: 'Unit Load Width (mm)',
    description: 'Header tooltip for Dimensions table, column Unit Load Width',
  },
  unitLoadDiameter: {
    id: 'maintainResultTableUnitLoadDiameter',
    defaultMessage: 'UL Diameter',
    description: 'Header for Dimensions table, column Unit Load Diameter',
  },
  unitLoadDiameterTooltip: {
    id: 'maintainResultTableUnitLoadDiameterTooltip',
    defaultMessage: 'Unit Load Diameter (mm)',
    description:
      'Header tooltip for Dimensions table, column Unit Load Diameter',
  },
  supplyChainNetVolume: {
    id: 'maintainResultTableSupplyChainNetVolume',
    defaultMessage: 'Supply Chain Net Vol.',
    description: 'Header for Dimensions table, column Supply Chain Net Volume',
  },
  supplyChainNetVolumeTooltip: {
    id: 'maintainResultTableSupplyChainNetVolumeTooltip',
    defaultMessage: 'Supply Chain Net Volume (dm³)',
    description:
      'Header tooltip for Dimensions table, column Supply Chain Net Volume',
  },
  numberOfPackages: {
    id: 'maintainResultTableNumberOfPackages',
    defaultMessage: 'No. of Packs',
    description:
      'Header for Dimensions table, column Number of Packages composing the article',
  },
  numberOfPackagesTooltip: {
    id: 'maintainResultTableNumberOfPackagesTooltip',
    defaultMessage: 'Number of packages',
    description:
      'Header tooltip for Dimensions table, column Number of Packages composing the article',
  },
});

const internalReplenishmentMessages = defineMessages({
  bufferStock: {
    id: 'maintainResultTableBufferStock',
    defaultMessage: 'Buffer Stock',
    description: 'Field/table header for Buffer Stock',
  },
  bufferStockTooltip: {
    id: 'maintainResultTableBufferStockTooltip',
    defaultMessage: 'Buffer Stock',
    description: 'Tooltip on field/table header for Buffer Stock',
  },
  numberMoves: {
    id: 'maintainResultTableNumberMoves',
    defaultMessage: '# Moves',
    description: 'Field/table header for number of moves',
  },
  numberMovesTooltip: {
    id: 'maintainResultTableNumberMovesTooltip',
    defaultMessage: '# Moves',
    description: 'Tooltip on field/table header for  number of moves',
  },
  numberMovesPerM3: {
    id: 'maintainResultTableNumberMovesPerM3',
    defaultMessage: 'Moves / sold m3',
    description: 'Field/table header for number of moves per sold cubic meter',
  },
  numberMovesPerM3Tooltip: {
    id: 'maintainResultTableNumberMovesPerM3Tooltip',
    defaultMessage: 'Moves / sold m3',
    description:
      'Tooltip on field/table header for  number of moves per sold cubic meter',
  },
  calcIrl: {
    id: 'maintainResultTableCalcIrl',
    defaultMessage: 'Calc. IRL',
    description:
      'Field/table header for Calculated Individual Replenishment Level',
  },
  calcIrlTooltip: {
    id: 'maintainResultTableCalcIrlTooltip',
    defaultMessage: 'Calculated Individual Replenishment Level',
    description:
      'Tooltip on field/table header for Calculated Individual Replenishment Level',
  },
  manIrl: {
    id: 'maintainResultTableManIrl',
    defaultMessage: 'Man. IRL',
    description: 'Field/table header for Manual Individual Replenishment Level',
  },
  manIrlTooltip: {
    id: 'maintainResultTableManIrlTooltip',
    defaultMessage: 'Manual Individual Replenishment Level',
    description:
      'Tooltip on field/table header for Manual Individual Replenishment Level',
  },
  maxIrl: {
    id: 'maintainResultTableMaxIrl',
    defaultMessage: 'Max. IRL',
    description: 'Field/table header for Manual Individual Replenishment Level',
  },
  maxIrlTooltip: {
    id: 'maintainResultTableMaxIrlTooltip',
    defaultMessage: 'Maximum Individual Replenishment Level',
    description:
      'Tooltip on field/table header for Maximum Individual Replenishment Level',
  },
  minIrl: {
    id: 'maintainResultTableMinIrl',
    defaultMessage: 'Min. IRL',
    description:
      'Field/table header for Minimum Individual Replenishment Level',
  },
  minIrlTooltip: {
    id: 'maintainResultTableMinIrlTooltip',
    defaultMessage: 'Minimum Individual Replenishment Level',
    description:
      'Tooltip on field/table header for Minimum Individual Replenishment Level',
  },
  shortageRisk: {
    id: 'maintainResultTableShortageRisk',
    defaultMessage: 'SH/Risk Flag',
    description: 'Field/table header for Shortage and Risk Flag',
  },
  shortageRiskTooltip: {
    id: 'maintainResultTableShortageRiskTooltip',
    defaultMessage: 'Shortage/Risk Flag',
    description: 'Tooltip on field/table header for Shortage and Risk Flag',
  },
});

const productInfoMessages = defineMessages({
  commercialClass: {
    id: 'maintainResultTableCommercialClass',
    defaultMessage: 'Com. Class',
    description: 'Field/table header for Commercial Class',
  },
  commercialClassTooltip: {
    id: 'maintainResultTableCommercialClassTooltip',
    defaultMessage: 'Commercial Class',
    description: 'Tooltip on field/table header for Commercial Class',
  },

  serviceLevelClassification: {
    id: 'maintainResultTableServiceLevelClassification',
    defaultMessage: 'SL',
    description: 'Field/table header for Service Level Classification',
  },
  serviceLevelClassificationTooltip: {
    id: 'maintainResultTableServiceLevelClassificationTooltip',
    defaultMessage: 'Service Level Classification',
    description:
      'Tooltip on field/table header for Service Level Classification',
  },

  keyArticle: {
    id: 'maintainResultTableKeyArticle',
    defaultMessage: 'Key Art.',
    description: 'Field/table header for Key Article',
  },
  // TODO: Display line breaks in tooltip or convey this information in another way.
  keyArticleTooltip: {
    id: 'maintainResultTableKeyArticleTooltip',
    defaultMessage: `
      N New article (King)
      T Greatest Turnover
      M Greatest Gross margin (Ace)
      L Lowest Price (Ace)
      S Sustainability (Ace)
      * More than one key article`,
    description: 'Tooltip on field/table header for Key Article',
  },

  styleGroup: {
    id: 'maintainResultTableStyleGroup',
    defaultMessage: 'Style',
    description: 'Field/table header for Style Group',
  },
  styleGroupTooltip: {
    id: 'maintainResultTableStyleGroupTooltip',
    defaultMessage: 'Style Group',
    description: 'Tooltip on field/table header for Style Group',
  },

  price: {
    id: 'maintainResultTablePrice',
    defaultMessage: 'Price',
    description: 'Field/table header for Price',
  },
  priceTooltip: {
    id: 'maintainResultTablePriceTooltip',
    defaultMessage: 'Price',
    description: 'Tooltip on field/table header for Price',
  },

  localPrice: {
    id: 'maintainResultTableLocalPrice',
    defaultMessage: 'Local Price',
    description: 'Field/table header for Local Price',
  },
  localPriceTooltip: {
    id: 'maintainResultTableLocalPriceTooltip',
    defaultMessage: 'Local Price',
    description: 'Tooltip on field/table header for Local Price',
  },

  priceLevel: {
    id: 'maintainResultTablePriceLevel',
    defaultMessage: 'Price lvl',
    description: 'Field/table header for Price Level',
  },
  priceLevelTooltip: {
    id: 'maintainResultTablePriceLevelTooltip',
    defaultMessage: 'Price Level',
    description: 'Tooltip on field/table header for Price Level',
  },

  familyPrice: {
    id: 'maintainResultTableFamilyPrice',
    defaultMessage: 'Family Price',
    description: 'Field/table header for Family Price',
  },
  familyPriceTooltip: {
    id: 'maintainResultTableFamilyPriceTooltip',
    defaultMessage: 'Family Price',
    description: 'Tooltip on field/table header for Family Price',
  },

  designer: {
    id: 'maintainResultTableDesignerName',
    defaultMessage: 'Designer',
    description: 'Field/table header for Designer Name',
  },
  designerTooltip: {
    id: 'maintainResultTableDesignerNameTooltip',
    defaultMessage: 'Designer Name',
    description: 'Tooltip on field/table header for Designer Name',
  },
});

const keyArticleMessages: Record<string, MessageDescriptorWithEnum> =
  defineMessages({
    keyArticleNewArticle: {
      id: 'maintainResultTableKeyArticleNewArticle',
      defaultMessage: 'New Article',
      enumValue: KeyArticleEnum.INDICATOR_VALUE_KING,
    },
    keyArticleGreatestTurnover: {
      id: 'maintainResultTableKeyArticleGreatestTurnover',
      defaultMessage: 'Greatest Turnover',
      enumValue: KeyArticleEnum.INDICATOR_VALUE_TURNOVER_ACE,
    },
    keyArticleGreatestGrossMargin: {
      id: 'maintainResultTableKeyArticleGreatestGrossMargin',
      defaultMessage: 'Greatest Gross Margin',
      enumValue: KeyArticleEnum.INDICATOR_VALUE_PROFIT_ACE,
    },
    keyArticleLowestPrice: {
      id: 'maintainResultTableKeyArticleLowestPrice',
      defaultMessage: 'Lowest Price',
      enumValue: KeyArticleEnum.INDICATOR_VALUE_BTI_ACE,
    },
    keyArticleSustainability: {
      id: 'maintainResultTableKeyArticleSustainability',
      defaultMessage: 'Sustainability',
      enumValue: KeyArticleEnum.INDICATOR_VALUE_SUSTAINABLE_ACE,
    },
  });

const priceLevelMessages: Record<string, MessageDescriptorWithEnum> =
  defineMessages({
    priceLevelUnspecified: {
      id: 'maintainResultTablePriceLevelUnspecified',
      defaultMessage: 'Unspecified',
      enumValue: PriceLevelEnum.QUALITY_NAME_UNSPECIFIED,
    },
    priceLevelBackOffPrice: {
      id: 'maintainResultTablePriceLevelBackOffPrice',
      defaultMessage: 'Back Off Price',
      enumValue: PriceLevelEnum.QUALITY_NAME_BACK_OFF_PRICE,
    },
    priceLevelBreathTakingItem: {
      id: 'maintainResultTablePriceLevelBreathTakingItem',
      defaultMessage: 'Breath Taking Item',
      enumValue: PriceLevelEnum.QUALITY_NAME_BREATH_TAKING_ITEM,
    },
    priceLevelHigh: {
      id: 'maintainResultTablePriceLevelHigh',
      defaultMessage: 'High',
      enumValue: PriceLevelEnum.QUALITY_NAME_HIGH,
    },
    priceLevelKnockOutPrice: {
      id: 'maintainResultTablePriceLevelKnockOutPrice',
      defaultMessage: 'Knock Out Price',
      enumValue: PriceLevelEnum.QUALITY_NAME_KNOCK_OUT_PRICE,
    },
    priceLevelLow: {
      id: 'maintainResultTablePriceLevelLow',
      defaultMessage: 'Low',
      enumValue: PriceLevelEnum.QUALITY_NAME_LOW,
    },
    priceLevelMedium: {
      id: 'maintainResultTablePriceLevelMedium',
      defaultMessage: 'Medium',
      enumValue: PriceLevelEnum.QUALITY_NAME_MEDIUM,
    },
  });

const priceLevelShortMessages: Record<string, MessageDescriptorWithEnum> =
  defineMessages({
    priceLevelUnspecifiedShort: {
      id: 'maintainResultTablePriceLevelUnspecifiedShort',
      defaultMessage: 'Unspec',
      enumValue: PriceLevelEnum.QUALITY_NAME_UNSPECIFIED,
    },
    priceLevelBackOffPriceShort: {
      id: 'maintainResultTablePriceLevelBackOffPriceShort',
      defaultMessage: 'BOP',
      enumValue: PriceLevelEnum.QUALITY_NAME_BACK_OFF_PRICE,
    },
    priceLevelBreathTakingItemShort: {
      id: 'maintainResultTablePriceLevelBreathTakingItemShort',
      defaultMessage: 'BTI',
      enumValue: PriceLevelEnum.QUALITY_NAME_BREATH_TAKING_ITEM,
    },
    priceLevelHighShort: {
      id: 'maintainResultTablePriceLevelHighShort',
      defaultMessage: 'High',
      enumValue: PriceLevelEnum.QUALITY_NAME_HIGH,
    },
    priceLevelKnockOutPriceShort: {
      id: 'maintainResultTablePriceLevelKnockOutPriceShort',
      defaultMessage: 'KO',
      enumValue: PriceLevelEnum.QUALITY_NAME_KNOCK_OUT_PRICE,
    },
    priceLevelLowShort: {
      id: 'maintainResultTablePriceLevelLowShort',
      defaultMessage: 'Low',
      enumValue: PriceLevelEnum.QUALITY_NAME_LOW,
    },
    priceLevelMediumShort: {
      id: 'maintainResultTablePriceLevelMediumShort',
      defaultMessage: 'Med',
      enumValue: PriceLevelEnum.QUALITY_NAME_MEDIUM,
    },
  });

const sellOutInfoMessages = defineMessages({
  stockReplenishmentCode: {
    id: 'maintainResultTableStockReplenishmentCode',
    defaultMessage: 'SRP Code',
    description: 'Field/table header for Stock Replenishment Code',
  },
  stockReplenishmentCodeTooltip: {
    id: 'maintainResultTableStockReplenishmentCodeTooltip',
    defaultMessage: 'Stock Replenishment Code',
    description: 'Tooltip on field/table header for Stock Replenishment Code',
  },

  soldLastWeek: {
    id: 'maintainResultTableSoldLastWeek',
    defaultMessage: 'Sold Last Wk',
    description: 'Field/table header for Quantity Sold Last Week',
  },
  soldLastWeekTooltip: {
    id: 'maintainResultTableSoldLastWeekTooltip',
    defaultMessage: 'Quantity Sold Last Week',
    description: 'Tooltip on field/table header for Quantity Sold Last Week',
  },

  currentTotalStockQuantity: {
    id: 'maintainResultTableCurrentTotalStockQuantity',
    defaultMessage: 'Current Tot. Stock Qty.',
    description: 'Field/table header for Current Total stock quantity',
  },
  currentTotalStockQuantityTooltip: {
    id: 'maintainResultTableCurrentTotalStockQuantityTooltip',
    defaultMessage: '(Current) Total stock quantity',
    description:
      'Tooltip on field/table header for Current Total stock quantity',
  },

  restToOrderQuantity: {
    id: 'maintainResultTableRestToOrderQuantity',
    defaultMessage: 'RtO',
    description: 'Field/table header for Rest to Order Quantity',
  },
  restToOrderQuantityTooltip: {
    id: 'maintainResultTableRestToOrderQuantityTooltip',
    defaultMessage: 'Rest to Order Quantity',
    description: 'Tooltip on field/table header for Rest to Order Quantity',
  },

  restToReceiveQuantity: {
    id: 'maintainResultTableRestToReceiveQuantity',
    defaultMessage: 'RtR',
    description: 'Field/table header for Rest to Receive Quantity',
  },
  restToReceiveQuantityTooltip: {
    id: 'maintainResultTableRestToReceiveQuantityTooltip',
    defaultMessage: 'Rest to Receive Quantity',
    description: 'Tooltip on field/table header for Rest to Receive Quantity',
  },

  overorderAllowed: {
    id: 'maintainResultTableOverorderAllowed',
    defaultMessage: 'Overorder',
    description: 'Field/table header for Overorder Allowed',
  },
  overorderAllowedTooltip: {
    id: 'maintainResultTableOverorderAllowedTooltip',
    defaultMessage: 'Overorder Allowed?',
    description: 'Tooltip on field/table header for Overorder Allowed',
  },

  sendRestQuantity: {
    id: 'maintainResultTableSendRestQuantity',
    defaultMessage: 'Send Rest Qty',
    description: 'Field/table header for Send Rest Quantity',
  },
  sendRestQuantityTooltip: {
    id: 'maintainResultTableSendRestQuantityTooltip',
    defaultMessage: 'Send Rest Quantity at push out',
    description: 'Tooltip on field/table header for Send Rest Quantity',
  },

  calculatedSellOutDate: {
    id: 'maintainResultTableCalculatedSellOutDate',
    defaultMessage: 'Calc Sell Out Date',
    description: 'Field/table header for Calculated Sell Out Date',
  },
  calculatedSellOutDateTooltip: {
    id: 'maintainResultTableCalculatedSellOutDateTooltip',
    defaultMessage: 'Calculated Sell Out Date',
    description: 'Tooltip on field/table header for Calculated Sell Out Date',
  },

  calculatedRemainingQuantityAtEDS: {
    id: 'maintainResultTableCalculatedRemainingQuantityAtEDS',
    defaultMessage: 'Calc Remain Qty at EDS',
    description: 'Field/table header for Calculated remaining quantity at EDS',
  },
  calculatedRemainingQuantityAtEDSTooltip: {
    id: 'maintainResultTableCalculatedRemainingQuantityAtEDSTooltip',
    defaultMessage: 'Calculated Remaining Quantity at Sales End Date',
    description:
      'Tooltip on field/table header for Calculated remaining quantity at EDS',
  },

  calculatedRemainingVolumeAtEDS: {
    id: 'maintainResultTableCalculatedRemainingVolumeAtEDS',
    defaultMessage: 'Calc Remain m³ at EDS',
    description: 'Field/table header for Calculated remaining volume at EDS',
  },
  calculatedRemainingVolumeAtEDSTooltip: {
    id: 'maintainResultTableCalculatedRemainingVolumeAtEDSTooltip',
    defaultMessage: 'Calculated Remaining m³ at Sales End Date',
    description:
      'Tooltip on field/table header for Calculated remaining volume at EDS',
  },

  readyForPlanning: {
    id: 'maintainResultTableReadyForPlanning',
    defaultMessage: 'Ready for planning',
    description: 'Field/table header for Ready for Planning',
  },
  readyForPlanningTooltip: {
    id: 'maintainResultTableReadyForPlanningTooltip',
    defaultMessage: 'Ready for sales planning',
    description: 'Tooltip on field/table header for Ready for Planning',
  },
});

export {
  commercialInfoMessages,
  spaceNeedMessages,
  ssqInfoMessages,
  deliveryTypeReasonMessages,
  priceLevelMessages,
  priceLevelShortMessages,
  dimensionsMessages,
  internalReplenishmentMessages,
  productInfoMessages,
  keyArticleMessages,
  sellOutInfoMessages,
};
