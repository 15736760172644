import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import Tooltip from '@ingka/tooltip';
import { parseApiDate } from 'core/util/date';
import { findEnumMessage } from 'core/util/enum';
import {
  internalReplenishmentMessages,
  ssqInfoMessages,
} from 'views/Maintain/tableMessages';
import mm from 'views/Maintain/maintainMessages';
import { useGetStoreStructureTreeQuery } from 'views/StoreStructure/redux/structuresApi';
import { ItemDetailsDto } from 'apis/backendApi';
import './ItemDetails.scss';
import { StoreStructurePath } from 'core/commonTypes';
import LocationHierarchy from 'views/Maintain/components/ItemDetails/LocationHierarchy';
import AsyncSwitch, {
  OnAsyncSwitchChange,
} from 'components/AsyncSwitch/AsyncSwitch';
import { usePutItemParametersMutation } from 'views/Maintain/redux/itemApi';
import { useAsyncFeedback } from 'hooks/feedback';
import { useHasUpdateItemAssignedSalesLocationPrivilege } from 'hooks/privilege';
import SSRIcon from '@ingka/ssr-icon';
import missingIcon from '@ingka/ssr-icon/paths/missing';
import { isCloudApiEnabled } from 'core/apiConfig';
import cm from 'core/commonMessages';

type AttributeGridValueItemProps = {
  header: string;
  tooltip: string;
  value: string | ReactElement | null;
};
const AttributeGridValueItem: React.FC<AttributeGridValueItemProps> = ({
  header,
  tooltip,
  value,
}) => {
  return (
    <div className="grid-item">
      <Tooltip tooltipText={tooltip}>
        <h3>{header}</h3>
      </Tooltip>
      <span>{value}</span>
    </div>
  );
};

type AttributeGridCheckboxItemProps = {
  inputId: string;
  header: string;
  tooltip: string;
  content: { value: boolean; label: string | null };
  onChange?: OnAsyncSwitchChange;
};
const AttributeGridCheckboxItem: React.FC<AttributeGridCheckboxItemProps> = ({
  inputId,
  header,
  tooltip,
  content,
  onChange,
}) => {
  return (
    <div className="grid-item">
      <Tooltip tooltipText={tooltip}>
        <h3>{header}</h3>
      </Tooltip>
      <AsyncSwitch
        id={inputId}
        checked={content.value}
        label={content.label}
        onChange={onChange}
        disabled={onChange === undefined}
      />
    </div>
  );
};

type AttributeGridProps = {
  data: ItemDetailsDto;
  recommendedStructure: StoreStructurePath | null;
};

const AttributeGrid: React.FC<AttributeGridProps> = ({ data }) => {
  const hideMissingMHSAttributes = isCloudApiEnabled();
  const { $t, formatNumber, formatDate } = useIntl();
  const { isLoading: structureIsLoading } = useGetStoreStructureTreeQuery();

  const [putItemParameters] = usePutItemParametersMutation();

  const canUpdate = useHasUpdateItemAssignedSalesLocationPrivilege();

  const onFlexibilityPercentageChange = useAsyncFeedback<boolean>(
    async checked => {
      await putItemParameters({
        itemNo: data?.itemNo,
        flexibilityPercentage: checked ? 50 : 0,
      }).unwrap();
    },
    [data?.itemNo, putItemParameters],
    {
      messages: mm.useCases.changeFlexibilityPercentage,
    }
  );

  const onUseCommercialQuantityChange = useAsyncFeedback<boolean>(
    async checked => {
      await putItemParameters({
        itemNo: data?.itemNo,
        useCommercialQuantity: checked,
      }).unwrap();
    },
    [data?.itemNo, putItemParameters],
    {
      messages: mm.useCases.changeUseCommercialQuantity,
    }
  );

  const missingDataRender = () => (
    <span>
      <Tooltip tooltipText={$t(mm.maintainMissingDataTooltip)}>
        <SSRIcon paths={missingIcon} />
      </Tooltip>
    </span>
  );

  const cellSpec: Array<
    | {
        type: 'text';
        key: string;
        header: string;
        tooltip: string;
        valueRender: (data: ItemDetailsDto) => string | ReactElement;
      }
    | {
        type: 'checkbox';
        key: string;
        header: string;
        tooltip: string;
        value: (data: ItemDetailsDto) => { label: string; value: boolean };
        onChange?: OnAsyncSwitchChange;
      }
  > = [
    {
      type: 'text',
      key: 'pa',
      header: $t(mm.maintainItemDetailsProductArea),
      tooltip: $t(mm.maintainItemDetailsProductAreaTooltip),
      valueRender: data => data.productArea,
    },
    {
      type: 'text',
      key: 'ssd',
      header: $t(mm.maintainSalesStartDateShort),
      tooltip: $t(mm.maintainSalesStartDateTooltip),
      valueRender: data =>
        data.startDateSale
          ? formatDate(parseApiDate(data.startDateSale), {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })
          : '-',
    },
    {
      type: 'text',
      key: 'flexQty',
      header: $t(mm.maintainItemDetailsFlexQty),
      tooltip: $t(mm.maintainItemDetailsFlexQtyTooltip),
      valueRender: data => formatNumber(data.flexibilityOverAssq),
    },
    {
      type: 'checkbox',
      key: 'flexParam',
      header: $t(mm.maintainItemDetailsFlex),
      tooltip: $t(mm.maintainItemDetailsFlexTooltip),
      value: data => ({
        value: data.flexibilityPercentage > 0,
        label: formatNumber(data.flexibilityPercentage / 100, {
          style: 'percent',
        }),
      }),
      onChange: canUpdate ? onFlexibilityPercentageChange : undefined,
    },
    {
      type: 'text',
      key: 'stock',
      header: $t(mm.maintainItemDetailsTotalAndSgfStock),
      tooltip: $t(mm.maintainItemDetailsTotalAndSgfStockTooltip),
      valueRender: hideMissingMHSAttributes
        ? missingDataRender
        : data => {
            const lastUpdated =
              data.stockMetaSource === 'GIS'
                ? `${formatDate(data.stockMetaLastUpdated, {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}`
                : $t(cm.valueAtNightBatch);
            return (
              <>
                <Tooltip tooltipText={lastUpdated}>
                  <>
                    {formatNumber(data.totalStock)} (
                    {formatNumber(data.sgfStock)})
                  </>
                </Tooltip>
              </>
            );
          },
    },
    {
      type: 'text',
      key: 'calcIRL',
      header: $t(internalReplenishmentMessages.calcIrl),
      tooltip: $t(internalReplenishmentMessages.calcIrlTooltip),
      valueRender: hideMissingMHSAttributes
        ? missingDataRender
        : data =>
            formatNumber(data.internalReplenishmentLevel / 100, {
              style: 'percent',
            }),
    },
    {
      type: 'text',
      key: 'DTReason',
      header: $t(ssqInfoMessages.deliveryTypeReason),
      tooltip: $t(ssqInfoMessages.deliveryTypeReasonTooltip),
      valueRender: data => {
        const items = data.deliveryTypeReason
          .map(value => findEnumMessage(ssqInfoMessages, value))
          .map(message => (message ? $t(message) : ''));
        return items.join(' / ');
      },
    },
    {
      type: 'text',
      key: 'eds',
      header: $t(mm.maintainEndSalesDateShort),
      tooltip: $t(mm.maintainEndSalesDateTooltip),
      valueRender: data =>
        data.endDateSale?.trim()
          ? formatDate(parseApiDate(data.endDateSale.trim()), {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })
          : '-',
    },
    {
      type: 'checkbox',
      key: 'auto',
      header: $t(mm.maintainItemDetailsAuto),
      tooltip: $t(mm.maintainItemDetailsAutoTooltip),
      value: data => ({ value: data.sgfAutoOrder, label: null }),
    },
    {
      type: 'checkbox',
      key: 'commercialQty',
      header: $t(mm.maintainItemDetailsCommercialQty),
      tooltip: $t(mm.maintainItemDetailsCommercialQtyTooltip),
      value: data => ({ value: data.useCommercialQuantity, label: null }),
      onChange: canUpdate ? onUseCommercialQuantityChange : undefined,
    },
    {
      type: 'text',
      key: 'nextIndelivery',
      header: 'Next Indelivery',
      tooltip: 'Next Indelivery',
      valueRender: hideMissingMHSAttributes
        ? missingDataRender
        : data =>
            data.nextIndelivery
              ? formatDate(parseApiDate(data.nextIndelivery.date), {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })
              : '',
    },
    {
      type: 'text',
      key: 'manIRL',
      header: $t(internalReplenishmentMessages.manIrl),
      tooltip: $t(internalReplenishmentMessages.manIrlTooltip),
      valueRender: hideMissingMHSAttributes
        ? missingDataRender
        : data =>
            formatNumber(data.manualReplenishmentLevel / 100, {
              style: 'percent',
            }),
    },
    {
      type: 'text',
      key: 'recRgId',
      header: 'Rec. Range Group',
      tooltip: 'Recommended Range Group',
      valueRender: data => {
        return <LocationHierarchy rangeId={data.recommendedRangeId} />;
      },
    },
    {
      type: 'text',
      key: 'shortRisk',
      header: $t(internalReplenishmentMessages.shortageRisk),
      tooltip: $t(internalReplenishmentMessages.shortageRiskTooltip),
      valueRender: hideMissingMHSAttributes
        ? missingDataRender
        : data => data.shortageRisk.toString(),
    },
    {
      type: 'text',
      key: 'multipack',
      header: 'Central Mup.Qty.(local)',
      tooltip: 'Central Multipack Quantity (local)',
      valueRender: data =>
        `${formatNumber(data.centralMultipackQuantity, {
          maximumFractionDigits: 1,
        })} (${
          data.sgfLocalMultipackQuantity || data.sgfLocalMultipackQuantity === 0
            ? formatNumber(data.sgfLocalMultipackQuantity, {
                maximumFractionDigits: 1,
              })
            : '-'
        })`,
    },
    {
      type: 'text',
      key: 'palletQty',
      header: $t(mm.maintainItemDetailsPalletQty),
      tooltip: $t(mm.maintainItemDetailsPalletQtyTooltip),
      valueRender: data =>
        formatNumber(data.palletQuantity, { maximumFractionDigits: 1 }),
    },
    {
      type: 'text',
      key: 'soldLastWeek',
      header: $t(mm.maintainItemDetailsSoldAndAverage),
      tooltip: $t(mm.maintainItemDetailsSoldAndAverageTooltip),
      valueRender: data =>
        `${
          data.soldLastWeek !== null
            ? formatNumber(data.soldLastWeek, {
                maximumFractionDigits: 1,
              })
            : '!'
        } / ${
          data.soldWeeklyAverage !== null
            ? formatNumber(data.soldWeeklyAverage, {
                maximumFractionDigits: 1,
              })
            : '!'
        }`,
    },
    {
      type: 'text',
      key: 'lastInventoryDate',
      header: $t(mm.maintainItemDetailsLastInventoryDate),
      tooltip: $t(mm.maintainItemDetailsLastInventoryDateTooltip),
      valueRender: hideMissingMHSAttributes
        ? missingDataRender
        : data =>
            data.lastInventoryDate
              ? formatDate(parseApiDate(data.lastInventoryDate), {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })
              : '',
    },
  ];

  if (structureIsLoading) {
    return <></>;
  }

  return (
    <div className="slm-item-details-grid">
      {cellSpec.map(cell => {
        if (cell.type === 'checkbox') {
          return (
            <AttributeGridCheckboxItem
              key={cell.key}
              inputId={cell.key}
              header={cell.header}
              tooltip={cell.tooltip}
              content={data ? cell.value(data) : { label: null, value: false }}
              onChange={cell.onChange}
            />
          );
        } else {
          return (
            <AttributeGridValueItem
              key={cell.key}
              header={cell.header}
              tooltip={cell.tooltip}
              value={data ? cell.valueRender(data) : ''}
            />
          );
        }
      })}
    </div>
  );
};

export default AttributeGrid;
