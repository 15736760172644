import React, { useMemo } from 'react';
import { CommonTableProps } from 'views/Maintain/types';
import AttributesTable from 'views/Maintain/components/Result/AttributesTable';
import { useStockFigureColumnDef } from 'views/Maintain/hooks/columns';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { SearchResultItemDto } from 'apis/backendApi';
import { internalReplenishmentMessages } from 'views/Maintain/tableMessages';
import { useIntl } from 'react-intl';
import maintainMessages from 'views/Maintain/maintainMessages';
import { useIntegerCell } from 'views/Maintain/hooks/cells';
import commonMessages from 'core/commonMessages';

const InternalReplenishmentTable: React.FC<CommonTableProps> = ({
  data,
  summary,
}) => {
  const { $t, formatNumber } = useIntl();
  const sgfStockFigureColumnDef = useStockFigureColumnDef('sgfStock');
  const integerCell = useIntegerCell();
  const onOffCell = useMemo(() => {
    const onText = $t(commonMessages.on);
    const offText = $t(commonMessages.off);
    return (props: CellContext<SearchResultItemDto, boolean>) =>
      props.getValue() ? onText : offText;
  }, [$t]);

  const columns: ColumnDef<SearchResultItemDto>[] = useMemo(() => {
    return [
      sgfStockFigureColumnDef,
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.internalReplenishment.movesPerSoldM3,
        id: 'internalReplenishment_movesPerSoldM3',
        meta: {
          headerMessages: {
            text: $t(internalReplenishmentMessages.numberMovesPerM3),
            tooltip: $t(internalReplenishmentMessages.numberMovesPerM3Tooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.internalReplenishment.moves,
        id: 'internalReplenishment_moves',
        meta: {
          headerMessages: {
            text: $t(internalReplenishmentMessages.numberMoves),
            tooltip: $t(internalReplenishmentMessages.numberMovesTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.internalReplenishment.sgfAutoOrder,
        id: 'internalReplenishment_sgfAutoOrder',
        meta: {
          headerMessages: {
            text: $t(maintainMessages.maintainItemDetailsAuto),
            tooltip: $t(maintainMessages.maintainItemDetailsAutoTooltip),
          },
        },
        cell: onOffCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.internalReplenishment.minIRL,
        id: 'internalReplenishment_minIRL',
        meta: {
          headerMessages: {
            text: $t(internalReplenishmentMessages.minIrl),
            tooltip: $t(internalReplenishmentMessages.minIrlTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.internalReplenishment.maxIRL,
        id: 'internalReplenishment_maxIRL',
        meta: {
          headerMessages: {
            text: $t(internalReplenishmentMessages.maxIrl),
            tooltip: $t(internalReplenishmentMessages.maxIrlTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.internalReplenishment.calculatedIRL,
        id: 'internalReplenishment_calculatedIRL',
        meta: {
          headerMessages: {
            text: $t(internalReplenishmentMessages.calcIrl),
            tooltip: $t(internalReplenishmentMessages.calcIrlTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.internalReplenishment.manualIRL,
        id: 'internalReplenishment_manualIRL',
        meta: {
          headerMessages: {
            text: $t(internalReplenishmentMessages.manIrl),
            tooltip: $t(internalReplenishmentMessages.manIrlTooltip),
          },
        },
        cell: props => {
          const value = props.getValue() as number;
          if (value === null || value < 0) {
            return '-';
          }
          return formatNumber(value, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.internalReplenishment.shortageRisk,
        id: 'internalReplenishment_shortageRisk',
        meta: {
          headerMessages: {
            text: $t(internalReplenishmentMessages.shortageRisk),
            tooltip: $t(internalReplenishmentMessages.shortageRiskTooltip),
          },
        },
        cell: integerCell,
      },
    ];
  }, [$t, formatNumber, integerCell, onOffCell, sgfStockFigureColumnDef]);

  return <AttributesTable data={data} columns={columns} summary={summary} />;
};

export default InternalReplenishmentTable;
