import { defineMessages } from 'react-intl';

const messages = defineMessages({
  appTitleAbbr: {
    id: 'appTitleAbbr',
    defaultMessage: 'SLM',
    description: 'Abbreviated application title for Sales Location Management',
  },
  headerNavigationMenu: {
    id: 'headerNavigationMenu',
    defaultMessage: 'Menu',
    description:
      'Button text for menu button on page header to engage navigation menu',
  },
  mainMenu: {
    id: 'mainMenu',
    defaultMessage: 'Main menu',
  },
  loginButton: {
    id: 'loginButton',
    defaultMessage: 'Log In',
    description: 'Text on button that logs in the user',
  },
  editButton: {
    id: 'OFC_O1_EDIT_BTN',
    defaultMessage: 'Edit',
    description: 'Text on button or tooltip for button to edit something',
  },
  deleteButton: {
    id: 'OFC_O1_DELETE_BTN',
    defaultMessage: 'Delete',
    description: 'Text on button or tooltip for button to delete something',
  },
  hiddenStructureItem: {
    id: 'hiddenStructureItem',
    defaultMessage:
      'Hidden {type,select, SpecialityShop {speciality shop} RangeGroup {range group} SalesLocation {sales location} other {}}',
    description:
      'Tooltip for button to toggle store structure item visibility, showing current status of hidden. Parameter for either Speciality shop or Range Group',
  },
  visibleStructureItem: {
    id: 'visibleStructureItem',
    defaultMessage:
      'Visible {type,select, SpecialityShop {speciality shop} RangeGroup {range group} SalesLocation {sales location} other {}}',
    description:
      'Tooltip for button to toggle store structure item visibility, showing current status of visible. Parameter for either Speciality shop or Range Group',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading...',
  },
  error: {
    id: 'error',
    defaultMessage: 'Error',
  },
  unknownError: {
    id: 'unknownError',
    defaultMessage: 'Unknown error: {message}',
    description:
      'Fallback message when the application encounters an unknown error, with {message} being the English message',
  },
  success: {
    id: 'success',
    defaultMessage: 'Success',
  },
  update: {
    id: 'update',
    defaultMessage: 'Update',
  },
  calculating: {
    id: 'calculating',
    defaultMessage: 'Calculating...',
  },
  notFoundTitle: {
    id: 'notFoundTitle',
    defaultMessage: 'Not Found',
  },
  notFoundBody: {
    id: 'notFoundBody',
    defaultMessage: 'The item could not be found.',
  },
  noResultsTitle: {
    id: 'noResultsTitle',
    defaultMessage: 'No Results',
  },
  noResultsBody: {
    id: 'noResultsBody',
    defaultMessage: 'No results found',
  },
  notImplementedTitle: {
    id: 'notImplementedTitle',
    defaultMessage: 'Not Implemented',
  },
  notImplementedBody: {
    id: 'notImplementedBody',
    defaultMessage: 'This function is not yet implemented.',
  },
  forbiddenTitle: {
    id: 'forbiddenTitle',
    defaultMessage: 'Forbidden',
  },
  forbiddenBody: {
    id: 'forbiddenBody',
    defaultMessage: 'Your account does not have the required permissions.',
  },
  selectStoreTitle: {
    id: 'selectStoreTitle',
    defaultMessage: 'Select Store',
  },
  selectStoreBody: {
    id: 'selectStoreBody',
    defaultMessage: 'Please select a store from the Store menu to proceed.',
  },
  selectStorePlaceholder: {
    id: 'selectStorePlaceholder',
    defaultMessage: 'Choose a store, or start typing to filter list',
  },
  apply: {
    id: 'apply',
    defaultMessage: 'Apply',
  },
  ok: {
    id: 'ok',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  yes: {
    id: 'yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'no',
    defaultMessage: 'No',
  },
  close: {
    id: 'close',
    defaultMessage: 'Close',
  },
  search: {
    id: 'search',
    defaultMessage: 'Search',
  },
  reset: {
    id: 'reset',
    defaultMessage: 'Reset',
    description: 'Reset form to initial state',
  },
  retry: {
    id: 'retry',
    defaultMessage: 'Retry',
    description: 'Retry operation',
  },
  articleNumber: {
    id: 'articleNumber',
    defaultMessage: 'Art. No.',
  },
  articleNumberTooltip: {
    id: 'articleNumberTooltip',
    defaultMessage: 'Article Number',
  },
  articleName: {
    id: 'articleName',
    defaultMessage: 'Art. Name',
  },
  articleNameTooltip: {
    id: 'articleNameTooltip',
    defaultMessage: 'Article Name',
  },
  description: {
    id: 'description',
    defaultMessage: 'Description',
  },
  noDescription: {
    id: 'noDescription',
    defaultMessage: '[No description]',
  },
  resetToDefault: {
    id: 'resetToDefault',
    defaultMessage: 'Reset to default',
  },
  genericErrorTitle: {
    id: 'genericErrorTitle',
    defaultMessage: 'An error occurred',
  },
  genericErrorMessage: {
    id: 'genericErrorMessage',
    defaultMessage: 'The application encountered an error.',
  },
  searchResults: {
    id: 'searchResults',
    defaultMessage: 'Search results',
  },
  on: {
    id: 'on',
    defaultMessage: 'On',
    description: 'Indicates on/off state',
  },
  off: {
    id: 'off',
    defaultMessage: 'Off',
    description: 'Indicates on/off state',
  },
  print: {
    id: 'print',
    defaultMessage: 'Print',
  },
  next: {
    id: 'next',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'previous',
    defaultMessage: 'Previous',
  },
  atStart: {
    id: 'atStart',
    defaultMessage: 'At start',
  },
  atEnd: {
    id: 'atEnd',
    defaultMessage: 'At end',
  },
  storeMenu: {
    id: 'storeMenu',
    defaultMessage: 'Store menu',
  },
  storeMenuTitle: {
    id: 'storeMenuTitle',
    defaultMessage: 'Store selection menu',
    description: 'Tooltip for Store menu button',
  },
  authenticating: {
    id: 'authenticating',
    defaultMessage: 'Authenticating...',
  },
  authenticationFailed: {
    id: 'authenticationFailed',
    defaultMessage: 'Authentication failed',
  },
  chooseStore: {
    id: 'chooseStore',
    defaultMessage: 'Choose store',
  },
  storeListLoadError: {
    id: 'storeListLoadError',
    defaultMessage: 'Failed to load store list',
  },
  topic: {
    id: 'topic',
    defaultMessage: 'Topic',
  },
  date: {
    id: 'date',
    defaultMessage: 'Date',
  },
  when: {
    id: 'when',
    defaultMessage: 'When',
  },
  helpTitle: {
    id: 'helpTitle',
    defaultMessage: 'Help',
  },
  helpText: {
    id: 'helpText',
    defaultMessage: 'Help text',
  },
  storeStructureLoadingError: {
    id: 'storeStructureLoadingError',
    defaultMessage:
      'There was a problem loading the Store information. Please try again later.',
  },
  showHistoryButtonTitle: {
    id: 'showHistoryButtonTitle',
    defaultMessage: 'Open change history',
  },
  emptyHistory: {
    id: 'emptyHistory',
    defaultMessage: 'Empty History',
    description:
      'Text instead of tabular data when there is a lack of historic data',
  },
  historyLoadingError: {
    id: 'historyLoadingError',
    defaultMessage: 'Failed to load history',
  },

  cookieConsentPopupTitle: {
    id: 'cookieConsentPopupTitle',
    defaultMessage: 'Cookie Policy',
    description:
      'Header on popup for Cookie policy, for users who have not taken an active decision on cookie consent',
  },
  cookieConsentPopupBodyText: {
    id: 'cookieConsentPopupBodyText',
    defaultMessage:
      'This site uses cookies to function. Additional cookies allows for usage statistics used to improve the application.',
    description: 'Body text on popup for Cookie policy',
  },
  cookieConsentPopupAllowStatisticsButton: {
    id: 'cookieConsentPopupAllowStatisticsButton',
    defaultMessage: 'Allow statistics',
    description:
      'Popup for Cookie Policy. Button for only allowing statistics.',
  },
  cookieConsentPopupEssentialButton: {
    id: 'cookieConsentPopupEssentialButton',
    defaultMessage: 'Essential only',
    description:
      'Popup for Cookie Policy. Button for only allowing essential cookies.',
  },

  privacyPolicyPanelHeader: {
    id: 'privacyPolicyPanelHeader',
    defaultMessage: 'Privacy Policy',
    description: 'Header on panel for privacy policy',
  },

  appTitle: {
    id: 'appTitle',
    defaultMessage: 'Sales Location Management',
  },
  maintainTitle: {
    id: 'maintainTitle',
    defaultMessage: 'O11 Maintain Sales Location',
  },
  storeStructureTitle: {
    id: 'storeStructureTitle',
    defaultMessage: 'O12 Sales Location Structure',
  },
  parametersTitle: {
    id: 'parametersTitle',
    defaultMessage: 'O14 Parameters',
  },
  summariesTitle: {
    id: 'summariesTitle',
    defaultMessage: 'O16 Summaries',
  },
  importTitle: {
    id: 'importTitle',
    defaultMessage: 'O17 Import',
  },
  serviceOfficeTitle: {
    id: 'serviceOfficeTitle',
    defaultMessage: 'Service Office',
  },
  userManagementTitle: {
    id: 'userManagementTitle',
    defaultMessage: 'User Management',
  },
  devToolsTitle: {
    id: 'devToolsTitle',
    defaultMessage: 'DevTools',
    description:
      'Developer Tools menu item. Note: does not need to be translated.',
  },
  itemComputerTitle: {
    id: 'itemComputerTitle',
    defaultMessage: 'Item Computer',
    description: 'Item Computer heading. Note: does not need to be translated.',
  },
  actionsTitle: {
    id: 'actionsTitle',
    defaultMessage: 'Actions',
  },

  noArticleTitle: {
    id: 'noArticleTitle',
    defaultMessage: 'Article not found',
  },
  noArticleBody: {
    id: 'noArticleBody',
    defaultMessage: 'No article found for this article number.',
  },

  empty: {
    id: 'empty',
    defaultMessage: 'Empty',
  },

  details: {
    id: 'details',
    defaultMessage: 'Details',
  },
  errorOccurred: {
    id: 'errorOccurred',
    defaultMessage: 'An error has occurred',
    description: 'Generic fallback error message',
  },

  weekPrefix: {
    id: 'weekPrefix',
    defaultMessage: 'W',
    description: 'Prefix denoting the week number. E.g. W52, W01, etc.',
  },

  sunday: {
    id: 'sunday',
    defaultMessage: 'Sunday',
  },
  monday: {
    id: 'monday',
    defaultMessage: 'Monday',
  },
  tuesday: {
    id: 'tuesday',
    defaultMessage: 'Tuesday',
  },
  wednesday: {
    id: 'wednesday',
    defaultMessage: 'Wednesday',
  },
  thursday: {
    id: 'thursday',
    defaultMessage: 'Thursday',
  },
  friday: {
    id: 'friday',
    defaultMessage: 'Friday',
  },
  saturday: {
    id: 'saturday',
    defaultMessage: 'Saturday',
  },

  dateTime: {
    id: 'dateTime',
    defaultMessage: 'Date/Time',
  },
  userName: {
    id: 'userName',
    defaultMessage: 'User name',
  },

  valueAtNightBatch: {
    id: 'valueAtNightBatch',
    defaultMessage: 'At night batch',
    description: 'Message indicating a value was accurate at night batch',
  },

  selectSingleFileButton: {
    id: 'selectSingleFileButton',
    defaultMessage: 'Select {extension} file',
    description:
      'Button label for single file selection with {extension} being the filename extension.',
  },
  selectMultipleFilesButton: {
    id: 'selectMultipleFilesButton',
    defaultMessage: 'Select {extension} files',
    description:
      'Button label for multiple file selection with {extension} being the filename extension.',
  },

  statusCode501Title: {
    id: 'statusCode501Title',
    defaultMessage: 'Not Implemented',
    description: 'Error title for functionality that is not implemented.',
  },
  statusCode501Body: {
    id: 'statusCode501Body',
    defaultMessage: 'This functionality is not implemented.',
    description: 'Error message for functionality that is not implemented.',
  },
});

export default messages;
