import React, { useMemo } from 'react';
import { CommonTableProps } from 'views/Maintain/types';
import { useIntl } from 'react-intl';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  BooleanEnum,
  CalcSellOutDateStateEnum,
  SearchResultItemDto,
} from 'apis/backendApi';
import { sellOutInfoMessages } from 'views/Maintain/tableMessages';
import AttributesTable from 'views/Maintain/components/Result/AttributesTable';
import {
  useAvailabilityColumnDef,
  useEdsColumnDef,
  useFamilyPriceColumnDef,
  useForecastColumnDef,
  useLocalPriceColumnDef,
  usePriceColumnDef,
  useStockFigureColumnDef,
} from 'views/Maintain/hooks/columns';
import { parseApiDate } from 'core/util/date';
import { useIntegerCell } from 'views/Maintain/hooks/cells';
import SSRIcon from '@ingka/ssr-icon';
import { circleFilled } from '@ingka/ssr-icon/icons';

function yesNoCellRenderer(
  props: CellContext<SearchResultItemDto, BooleanEnum>
) {
  const val = props.getValue();
  return val === BooleanEnum.True ? 'Y' : val === BooleanEnum.False ? 'N' : '';
}

const SellOutInfoTable: React.FC<CommonTableProps> = ({ data, summary }) => {
  const { $t, formatDate, formatNumber } = useIntl();
  const integerCell = useIntegerCell();
  const avinCoulmnDef = useAvailabilityColumnDef();
  const edsColumnDef = useEdsColumnDef();
  const priceColumnDef = usePriceColumnDef();
  const localPriceColumnDef = useLocalPriceColumnDef();
  const familyPriceColumnDef = useFamilyPriceColumnDef();
  const forecastColumnDef = useForecastColumnDef();
  const currentStockFigureColumnDef =
    useStockFigureColumnDef('currentTotStockQty');
  const columns: ColumnDef<SearchResultItemDto>[] = useMemo(() => {
    const intOrDashCell = props => {
      const value = props.getValue() as number;
      if (value === null) {
        return '-';
      }
      return formatNumber(value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    };

    return [
      avinCoulmnDef,
      {
        accessorFn: ({ sellOutInfo }) => {
          return sellOutInfo?.srpCode;
        },
        id: 'sellOut_srpCode',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.stockReplenishmentCode),
            tooltip: $t(sellOutInfoMessages.stockReplenishmentCodeTooltip),
          },
        },
        cell: props => {
          return props.getValue() || '';
        },
      },
      edsColumnDef,
      priceColumnDef,
      localPriceColumnDef,
      familyPriceColumnDef,
      forecastColumnDef,
      {
        accessorFn: ({ sellOutInfo }) => {
          return sellOutInfo?.soldLastWeek;
        },
        id: 'sellOut_soldLastWeek',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.soldLastWeek),
            tooltip: $t(sellOutInfoMessages.soldLastWeekTooltip),
          },
        },
        cell: integerCell,
      },
      currentStockFigureColumnDef,
      {
        accessorFn: ({ sellOutInfo }) => {
          return sellOutInfo?.restToOrderQty;
        },
        id: 'sellOut_restToOrderQuantity',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.restToOrderQuantity),
            tooltip: $t(sellOutInfoMessages.restToOrderQuantityTooltip),
          },
        },
        cell: intOrDashCell,
      },
      {
        accessorFn: ({ sellOutInfo }) => {
          return sellOutInfo?.restToReceiveQty;
        },
        id: 'sellOut_restToReceiveQuantity',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.restToReceiveQuantity),
            tooltip: $t(sellOutInfoMessages.restToReceiveQuantityTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: ({ sellOutInfo }) => {
          return sellOutInfo?.overorder;
        },
        id: 'sellOut_overorderAllowed',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.overorderAllowed),
            tooltip: $t(sellOutInfoMessages.overorderAllowedTooltip),
          },
        },
        cell: yesNoCellRenderer,
      },
      {
        accessorFn: ({ sellOutInfo }) => {
          return sellOutInfo?.sendRestQty;
        },
        id: 'sellOut_sendRestQty',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.sendRestQuantity),
            tooltip: $t(sellOutInfoMessages.sendRestQuantityTooltip),
          },
        },
        cell: yesNoCellRenderer,
      },
      {
        accessorFn: ({ sellOutInfo }) => {
          return {
            date: sellOutInfo?.calcSellOutDate || '',
            state: sellOutInfo?.calcSellOutDateState,
          };
        },
        id: 'sellOut_calcSellOutDate',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.calculatedSellOutDate),
            tooltip: $t(sellOutInfoMessages.calculatedSellOutDateTooltip),
          },

          getCellClassName(cellContext): string {
            const { state } = cellContext.getValue() as {
              date: string;
              state: CalcSellOutDateStateEnum;
            };
            switch (state) {
              case CalcSellOutDateStateEnum.BeforeEndDateSale:
                return 'slm-green';
              case CalcSellOutDateStateEnum.PastEndDateSale:
                return 'slm-red';
              default:
                return '';
            }
          },
        },
        cell: props => {
          const { date } = props.getValue() as {
            date: string;
            state: CalcSellOutDateStateEnum;
          };
          return date ? formatDate(parseApiDate(date)) : '-';
        },
      },
      {
        accessorFn: ({ sellOutInfo }) => sellOutInfo?.calcRemainQtyEds,
        id: 'sellOut_calcRemainQtyAtEDS',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.calculatedRemainingQuantityAtEDS),
            tooltip: $t(
              sellOutInfoMessages.calculatedRemainingQuantityAtEDSTooltip
            ),
          },
        },
        cell: intOrDashCell,
      },
      {
        accessorFn: ({ sellOutInfo }) => sellOutInfo?.calcRemainM3Eds,
        id: 'sellOut_calcRemainingM3AtEDS',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.calculatedRemainingVolumeAtEDS),
            tooltip: $t(
              sellOutInfoMessages.calculatedRemainingVolumeAtEDSTooltip
            ),
          },
        },
        cell: props => {
          const value = props.getValue() as number;
          if (value === null) {
            return '-';
          }
          return formatNumber(value, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 3,
          });
        },
      },
      {
        accessorFn: ({ sellOutInfo }) => sellOutInfo?.readyForPlanning,
        id: 'sellOut_readyForPlanning',
        meta: {
          headerMessages: {
            text: $t(sellOutInfoMessages.readyForPlanning),
            tooltip: $t(sellOutInfoMessages.readyForPlanningTooltip),
          },
        },
        cell: props => {
          const value = props.getValue() as BooleanEnum;
          const color = value === BooleanEnum.True ? 'green' : 'red';
          return <SSRIcon paths={circleFilled} style={{ color }} />;
        },
      },
    ];
  }, [
    avinCoulmnDef,
    $t,
    edsColumnDef,
    priceColumnDef,
    localPriceColumnDef,
    familyPriceColumnDef,
    forecastColumnDef,
    integerCell,
    currentStockFigureColumnDef,
    formatNumber,
    formatDate,
  ]);
  return <AttributesTable data={data} summary={summary} columns={columns} />;
};

export default SellOutInfoTable;
